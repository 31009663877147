import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Spin } from "antd";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { useNavigate, useSearchParams } from "react-router-dom";
import autoAnimate from '@formkit/auto-animate';
import db from "../../../firebase";
import { toastError } from "../../../helpers/toasters";
import { getAuctionDetails, getAuctions } from "../../../network";

// components
import ActiveAuction from "./ActiveAuction";
import AuctionInfo from "./AuctionInfo";
import Chat from "./Chat";

const AuctionChat = () => {
    const [joinedAuctions, setJoinedAuctions] = useState(null);
    const [activeAuction, setActiveAuction] = useState(null);
    const [auctionDetails, setAuctionDetails] = useState(null);
    const [allAuctions, setAllAuctions] = useState(null);
    const [loading, setLoading] = useState(true);
    const [closingAuction, setClosingAuction] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams({ auctionId: "" });
    const { authorization } = useSelector((state) => state.auth);
    const { dir } = useSelector((state) => state.language);
    const navigate = useNavigate();
    const auctionsParent = useRef(null);

    const handleCloseAuctionTab = async (auction, deleteAuction) => {
        try {
            const docRef = doc(db, "users", authorization.userId)
            const docSnap = await getDoc(docRef);
            // updated users joined auctions
            let joinedAuctionsArr = [];
            if (deleteAuction) {
                joinedAuctionsArr = docSnap.data().joinedAuctions.filter((obj) => obj.id === auction.id)
            } else {
                joinedAuctionsArr = structuredClone(docSnap.data().joinedAuctions)
                const tabIndx = joinedAuctionsArr.findIndex((ele) => (ele.auctionId === auction.id))
                if (tabIndx !== -1) {
                    joinedAuctionsArr[tabIndx].isTabActive = false;
                }
            }
            await setDoc(doc(db, "users", authorization.userId), {
                joinedAuctions: joinedAuctionsArr
            }, { merge: true });
            if (joinedAuctionsArr.filter((ele) => ele.isTabActive).length === 0) {
                navigate("/bidders")
            } else {
                // if it's the currently open tab
                if (auction.active) {
                    const firstInactiveAuction = joinedAuctions.find((auction) => !auction.active && auction.isTabActive)
                    searchParams.set("auctionId", firstInactiveAuction.id)
                    await getUserActiveAuctions(authorization.userId, firstInactiveAuction.id)
                    setSearchParams(searchParams)
                }
                getUserActiveAuctions(authorization.userId, searchParams.get("auctionId"))
            }
            setClosingAuction(null)
        } catch (error) {
            toastError(error)
        }
    }

    const getUserActiveAuctions = async (userId, auctionId) => {
        try {
            const docRef = doc(db, "users", userId)
            const docSnap = await getDoc(docRef);
            const joinedAuctions = docSnap.data().joinedAuctions;
            const hasUserJoinedAuction = joinedAuctions?.find((auction) => auction.auctionId === auctionId)
            if (joinedAuctions?.length > 0 && hasUserJoinedAuction) {
                const joinedAuctionsArr = [];
                joinedAuctions.forEach(async (auction, indx, arr) => {
                    const auctionObj = allAuctions.find((auc) => auc.id === auction.auctionId)
                    if (auctionObj) {
                        const isActive = auction.auctionId === auctionId;
                        const obj = {
                            id: auction.auctionId,
                            name: auctionObj.name,
                            isTabActive: auction.isTabActive,
                            active: isActive,
                        }
                        joinedAuctionsArr.push(obj);
                        if (isActive) {
                            setActiveAuction(obj)
                        }
                    } else {
                        const joinedAuctionsArr = joinedAuctions.filter((ele) => ele.auctionId !== auction.auctionId);
                        await setDoc(doc(db, "users", authorization.userId), {
                            joinedAuctions: joinedAuctionsArr
                        }, { merge: true });
                        if (joinedAuctionsArr.length === 0) {
                            navigate("/bidders")
                        }
                    }
                    if (indx === arr.length - 1) {
                        setJoinedAuctions(joinedAuctionsArr.filter((ele) => ele.isTabActive))
                    }
                })
            } else {
                navigate("/bidders")
            }
        } catch (error) {
            toastError(error)
        }
    }

    const handleActiveAuctionChange = (activeAuctionId) => {
        const allActiveAuctions = joinedAuctions.map((auction) => {
            if (auction.id === activeAuctionId) {
                auction.active = true;
                setActiveAuction(auction)
            } else {
                auction.active = false
            }
            return auction;
        })
        setJoinedAuctions(allActiveAuctions)
    }

    useEffect(() => {
        if (authorization && searchParams && joinedAuctions) {
            handleActiveAuctionChange(searchParams.get("auctionId"))
        }
    }, [authorization, searchParams]);

    // fires when language changes but not on first render
    useEffect(() => {
        if (joinedAuctions) {
            const newData = [];
            joinedAuctions.forEach((auction) => {
                const auctionData = allAuctions.find((auctionObj) => auctionObj.id === auction.id)
                if (auctionData) {
                    const newObj = { ...auction };
                    newObj.name = auctionData.name;
                    newData.push(newObj);
                }
            })
            setJoinedAuctions(newData)
        }
    }, [allAuctions])

    // fires one time on first render
    useEffect(() => {
        if (!joinedAuctions && allAuctions) {
            getUserActiveAuctions(authorization.userId, searchParams.get("auctionId"))
        }
    }, [allAuctions, authorization, searchParams])

    useEffect(() => {
        getAuctions(
            1,
            (res) => {
                if (res.success) {
                    setAllAuctions(res.data)
                } else {
                    toastError(res.message)
                }
            },
            (res) => {
                toastError(res.message)
            }
        )
    }, [dir])

    useEffect(() => {
        if (!activeAuction) return;
        setLoading(true)
        getAuctionDetails(
            { status: 1, auctionId: activeAuction.id },
            (res) => {
                setLoading(false)
                if (res.success) {
                    setAuctionDetails(res.data)
                } else {
                    toastError(res.message)
                }
            },
            (res) => {
                setLoading(false)
                toastError(res.message)
            }
        )
    }, [activeAuction, dir]);

    useEffect(() => {
        auctionsParent.current && autoAnimate(auctionsParent.current)
    }, [auctionsParent, auctionsParent.current]);

    return (
        <section dir={dir} className="auction-chat">
            <div ref={auctionsParent} className="d-flex align-items-center gap-4 flex-wrap mb-4 mb-lg-5">
                {joinedAuctions?.map((auction) => auction.isTabActive !== false ? (
                    <ActiveAuction
                        key={auction.id}
                        auction={auction}
                        onCloseAuction={(auction) => {
                            setClosingAuction(auction)
                            handleCloseAuctionTab(auction)
                        }}
                        disableClose={closingAuction?.id === auction.id}
                    />
                ) : (
                    ""
                ))}
            </div>
            {loading ? (
                <div className="py-5 d-flex justify-content-center">
                    <Spin size="large" spinning />
                </div>
            ) : (
                <div className="row m-0">
                    <div className="col-lg-6 mb-5 mb-lg-0 auction-chat__col">
                        <AuctionInfo data={auctionDetails} dir={dir} />
                    </div>
                    <div className="col-lg-6 auction-chat__col">
                        <Chat
                            auctionDetails={auctionDetails}
                            auction={activeAuction}
                            onCloseAuction={handleCloseAuctionTab}
                        />
                    </div>
                </div>
            )}
        </section>
    );
}

export default AuctionChat;