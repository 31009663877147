import { useNavigate } from "react-router-dom";
import { Spin } from "antd";

// assets
import auctionCloseActive from "../../../assets/icons/aution-close-btn--active.svg"
import auctionClose from "../../../assets/icons/auction-close-btn.svg"

const ActiveAuction = ({ auction, onCloseAuction, disableClose }) => {
    const navigate = useNavigate()
    return (
        <button
            type="button"
            onClick={() => {
                navigate(`/bidders/auction?auctionId=${auction?.id}`)
            }}
            className={`auction-chat__name-holder ${auction.active ? "auction-chat__name-holder--active" : ""} border-0 outline-none d-flex align-items-center text-decoration-none`}
        >
            <span className="fw-bold fsize-8">{auction?.name}</span>
            <span
                role="button"
                onClick={(e) => {
                    if (disableClose) return;
                    e.stopPropagation()
                    onCloseAuction(auction)
                }}
                className="d-block bg-transparent p-0 border-0 outline-none shadow-none"
            >
                {disableClose ? (
                    <Spin size="small" spinning />
                ): (
                        <img src = {auction.active ? auctionCloseActive : auctionClose} alt="" className="d-block img-fluid" />
                )}
            </span>
        </button>
    );
}

export default ActiveAuction;