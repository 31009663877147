const LuckyHourIcon = () => {
    return (
        <svg className="recycle-nav__nav-icon" width="24" height="24" viewBox="0 0 40 40" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1081_14648)">
                <path d="M18.4375 7.5V19.3528L12.7079 25.0823C12.0977 25.6926 12.0977 26.6819 12.7079 27.292C12.8528 27.4374 13.025 27.5526 13.2146 27.6311C13.4043 27.7097 13.6075 27.75 13.8128 27.7497C14.018 27.7499 14.2213 27.7096 14.4109 27.6311C14.6005 27.5526 14.7728 27.4373 14.9177 27.292L21.1049 21.1048C21.3979 20.8118 21.5625 20.4144 21.5625 20V7.5C21.5625 6.63703 20.8629 5.9375 20 5.9375C19.137 5.9375 18.4375 6.63703 18.4375 7.5Z" />
                <path d="M39.7279 28.5768L37.4275 27.9194C36.3915 27.6273 35.4479 27.0743 34.6868 26.3132C33.9257 25.5521 33.3727 24.6085 33.0806 23.5725L32.4232 21.2721C32.3902 21.1916 32.3339 21.1228 32.2617 21.0743C32.1894 21.0259 32.1043 21 32.0173 21C31.9303 21 31.8453 21.0259 31.773 21.0743C31.7007 21.1228 31.6445 21.1916 31.6114 21.2721L30.954 23.5725C30.662 24.6085 30.1089 25.5521 29.3479 26.3132C28.5868 27.0743 27.6431 27.6273 26.6072 27.9194L24.3067 28.5768C24.2184 28.6018 24.1407 28.655 24.0853 28.7283C24.03 28.8015 24 28.8909 24 28.9827C24 29.0745 24.03 29.1638 24.0853 29.2371C24.1407 29.3103 24.2184 29.3635 24.3067 29.3886L26.6072 30.046C27.6431 30.338 28.5868 30.8911 29.3479 31.6521C30.1089 32.4132 30.662 33.3569 30.954 34.3928L31.6114 36.6933C31.6365 36.7816 31.6897 36.8593 31.7629 36.9147C31.8362 36.9701 31.9255 37 32.0173 37C32.1091 37 32.1985 36.9701 32.2717 36.9147C32.345 36.8593 32.3982 36.7816 32.4232 36.6933L33.0806 34.3928C33.3727 33.3569 33.9257 32.4132 34.6868 31.6521C35.4479 30.8911 36.3915 30.338 37.4275 30.046L39.7279 29.3886C39.8162 29.3635 39.894 29.3103 39.9493 29.2371C40.0047 29.1638 40.0346 29.0745 40.0346 28.9827C40.0346 28.8909 40.0047 28.8015 39.9493 28.7283C39.894 28.655 39.8162 28.6018 39.7279 28.5768Z" />
                <path d="M38.4375 3.35938C37.5745 3.35938 36.875 4.05891 36.875 4.92188V9.26063C33.2381 3.55687 26.8868 0 20 0C14.6578 0 9.63539 2.08039 5.85781 5.85781C2.08039 9.63539 0 14.6578 0 20C0 25.3422 2.08039 30.3646 5.85781 34.1422C9.63539 37.9196 14.6578 40 20 40C20.0132 40 20.0259 39.9984 20.0391 39.998C20.0522 39.9984 20.0649 40 20.0781 40C20.6413 40 21.2099 39.9762 21.7685 39.9295C22.6284 39.8575 23.2672 39.102 23.1952 38.2421C23.1231 37.3822 22.3687 36.7431 21.5077 36.8155C21.0355 36.8549 20.5546 36.875 20.0781 36.875C20.0649 36.875 20.0522 36.8766 20.0391 36.877C20.0259 36.8766 20.0132 36.875 20 36.875C10.6951 36.875 3.125 29.3049 3.125 20C3.125 10.6951 10.6951 3.125 20 3.125C25.9967 3.125 31.5123 6.32133 34.5263 11.4062H30.2299C29.367 11.4062 28.6674 12.1058 28.6674 12.9688C28.6674 13.8317 29.367 14.5312 30.2299 14.5312H35C35.9174 14.5323 36.8172 14.2794 37.5998 13.8005C37.6481 13.7731 37.6949 13.743 37.7401 13.7105C39.0999 12.8159 40 11.2771 40 9.53125V4.92188C40 4.05891 39.3005 3.35938 38.4375 3.35938Z" />
            </g>
            <defs>
                <clipPath id="clip0_1081_14648">
                    <rect width="40" height="40" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default LuckyHourIcon;