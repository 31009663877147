import { Radio } from "antd";
import { useTranslation } from "react-i18next";

const TabsHolder = ({ active, onTabChange, mode }) => {
    const { t } = useTranslation();

    return (
        <div className="buy-and-supply__tabs-holder">
            <Radio.Group
                name="tabs"
                defaultValue={1}
                optionType="button"
                buttonStyle="solid"
                value={active}
                onChange={onTabChange}
                className="buy-and-supply__tabs-holder__main-holder d-flex justify-content-between align-items-center"
            >
                <Radio value={1} className="fsize-5 fw-bold buy-and-supply__tabs-holder__main-holder__tab">{t(`buyAndSupply.${mode}`)}</Radio>
                <Radio value={2} className="fsize-5 fw-bold buy-and-supply__tabs-holder__main-holder__tab">{t("buyAndSupply.balance")}</Radio>
            </Radio.Group>
        </div>
    );
}

export default TabsHolder;