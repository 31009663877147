import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// assets
import noAuctions from "../../assets/images/auctions.png"
import noSearchResults from "../../assets/images/no-search.png"

const EmptyAuctions = ({ allAuctions, search }) => {
    const { dir } = useSelector((state) => state.language);
    const { t } = useTranslation();

    return (
        <div dir={dir} className="bidders__empty-state pt-4">
            <img src={search ? noSearchResults : noAuctions} alt="" className={`img-fluid d-block mx-auto ${search ? "mb-3 bidders__empty-state__no-result-icon" : "mb-5"}`} />
            <h1 className="text-center text--secondary-4 mb-3 fw-bold fsize-4">{t(`bidders.${search ? "noResults" : "empty"}`)}</h1>
            {allAuctions ?
                <p className="mb-0 text-center text--secondary-4 mb-3 fw-bold fsize-8">{t("bidders.checkLater")}</p>
                : ""
            }
        </div>
    );
}

export default EmptyAuctions;