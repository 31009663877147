import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Spin } from "antd";
import { getSupplyItems } from "../../../network";
import { toastError } from '../../../helpers/toasters';

// components
import TabsHolder from "../TabsHolder";
import BalanceRow from "../BalanceRow";
import ItemsEmptyState from "../ItemsEmptyState";
import BalanceEmptyState from './../BalanceEmptyState';
import SupplyCard from "./SupplyCard";
import SupplyModal from "./SupplyModal";

const Supply = () => {
    const [activeTab, setActiveTab] = useState(1) // 1 --> Supply, 2 --> balance
    const [data, setData] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [renderMe, setRenderMe] = useState(false);
    const [isSupplyModalOpen, setIsSupplyModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const { dir } = useSelector((state) => state.language);

    const handleCancelModal = () => {
        setIsSupplyModalOpen(false);
        setSelectedItem(null);
    }

    const handleSubmitting = () => {
        handleCancelModal();
        setRenderMe(!renderMe);
    }

    const handleOpenSupplyModal = (data) => {
        setSelectedItem(data);
        setIsSupplyModalOpen(true);
    }

    const handleTabChange = (e) => {
        setActiveTab(e.target.value);
        setLoading(true);
    }

    useEffect(() => {
        getSupplyItems(
            (res) => {
                setLoading(false)
                if (res.success) {
                    if (activeTab === 1) {
                        const data = res.data.items.map((item) => {
                            let disableSupply = true;
                            const itemInBalance = res.data.userBuyAndSupplyBuyBalance.find((balanceObj) => balanceObj.typeId === item.categoryId)
                            if (itemInBalance && itemInBalance.amountInKilo >= item.quantityPerKilo) {
                                disableSupply = false
                            }
                            return {
                                ...item, disableSupply
                            }
                        })
                        setData(data)
                    } else {
                        setData(res.data.userBuyAndSupplyBuyBalance)
                    }
                } else {
                    toastError(res.message)
                }
            },
            (res) => {
                setLoading(false)
                toastError(res.message)
            }
        )
    }, [activeTab, renderMe])

    return (
        <>
            <div dir="ltr" className="buy-and-supply__header d-flex flex-column justify-content-end">
                <TabsHolder active={activeTab} mode={"supply"} onTabChange={handleTabChange} />
            </div>
            <div className={`buy-and-supply__items-holder ${activeTab === 2 ? "buy-and-supply__items-holder--balance" : ""}`}>
                {loading ? (
                    <div className="d-flex justify-content-center align-items-center pt-5">
                        <Spin size="large" />
                    </div>
                ) : data?.length === 0 ? (
                    activeTab === 1 ? (
                        <ItemsEmptyState mode="supply" />
                    ) : (
                        <BalanceEmptyState />
                    )
                ) : (
                    <div className={`${activeTab === 1 ? "buy-and-supply__items-holder__items" : "buy-and-supply__balance-holder"} row m-0`}>
                        {activeTab === 1 ? (
                            data?.map((ele, indx) => (
                                <div key={ele.itemId} className={`col-lg-6 ${indx % 2 === 0 ? dir === "ltr" ? "ps-lg-0" : "pe-lg-0" : dir === "ltr" ? "pe-lg-0" : "ps-lg-0"}`}>
                                    <SupplyCard data={ele} onSupply={handleOpenSupplyModal} />
                                </div>
                            ))
                        ) : (
                            data?.map((ele) => (
                                <div key={ele.typeId} className="col-12 px-0">
                                    <BalanceRow data={ele} />
                                </div>
                            ))
                        )}
                    </div>
                )}
            </div>
            {isSupplyModalOpen && (
                <SupplyModal
                    open={isSupplyModalOpen}
                    onCancel={handleCancelModal}
                    selectedItem={selectedItem}
                    onSubmit={handleSubmitting}
                />
            )}
        </>
    );
}

export default Supply;