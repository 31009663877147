import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Input } from "antd";

// assets
import searchIcon from "../../assets/icons/search-icon--green.svg"

const AuctionSearch = ({ onChange, value }) => {
    const { dir } = useSelector((state) => state.language);
    const { t } = useTranslation();

    return (
        <div className="bidders__search-holder">
            <Input
                placeholder={t("bidders.searchPlaceholder")}
                onChange={onChange}
                value={value}
                dir={dir}
                prefix={<img src={searchIcon} alt="" className="img-fluid d-block"/>}
                allowClear
            />
        </div>
    );
}

export default AuctionSearch;