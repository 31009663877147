import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button } from "antd";
import moment from "moment";
import { listenToAuctionUpdates } from "../../firebaseService";
import { doc, getDoc } from "firebase/firestore";
import db from "../../firebase";

// assets
import dateIcon from "../../assets/icons/auction-date-icon.svg"

const Auction = ({
    data,
    status,
    onJoin,
    joinBtnLoading,
    selectedAuctionId,
    endAuction,
    onView,
    onPay,
    dir,
    onParticipate
}) => {
    const [startAtTime, setStartAtTime] = useState("");
    const [lastPrice, setLastPrice] = useState(0);
    const [counter, setCounter] = useState("");
    const [unjoinedAuctionCounter, setUnjoinedAuctionCounter] = useState("");
    const { authorization } = useSelector((state) => state.auth);
    const [displayedDate, setDisplayedDate] = useState("");
    const intervalId = useRef();
    const unjoinedAuctionIntervalId = useRef();
    const { t } = useTranslation();
    const initialAuctionLength = 1800;

    const calculateCounter = (startAtTime) => {
        const now = new moment();
        const startTime = new moment(startAtTime, "DD/MM/YYYY - hh:mm:ss A");
        const duration = now.diff(startTime, "seconds");
        const remainingTimeInSeconds = initialAuctionLength - duration;
        const minutes = parseInt(remainingTimeInSeconds / 60);
        const seconds = remainingTimeInSeconds % 60;
        return { minutes, seconds }
    }

    const checkIfAuctionIsInFirebase = async (id) => {
        const docRef = doc(db, "auctions", id)
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) {
            unjoinedAuctionIntervalId.current = setInterval(() => {
                const { minutes, seconds } = calculateCounter(data.startDate);
                if (minutes >= 0 && seconds >= 0) {
                    setUnjoinedAuctionCounter(`${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds}`)
                } else {
                    setUnjoinedAuctionCounter("00:00")
                    endAuction(data)
                    clearInterval(intervalId)
                }
            }, 1000)
        }
    }

    useEffect(() => {
        if (data && dir) {
            let formattedDate = "";
            if (dir === "ltr") {
                formattedDate = data.startDate
            } else {
                const dateArr = data.startDate.split(" - ");
                const reversedDatePart = dateArr[0].split("/").reverse().join("/")
                formattedDate=`${reversedDatePart} - ${dateArr[1]}`
            }
            setDisplayedDate(formattedDate)
        }
    }, [data, dir])

    useEffect(() => {
        if (!data && status === 1) return;

        const unsubscribe = listenToAuctionUpdates(
            data.id,
            (lastPrice, startAt) => {
                setLastPrice(lastPrice);
                setStartAtTime(startAt);
                clearInterval(unjoinedAuctionIntervalId.current)
            })

        return () => {
            unsubscribe();
        }
    }, [data, status])

    useEffect(() => {
        if (startAtTime) {
            clearInterval(intervalId.current)
            intervalId.current = setInterval(() => {
                const { minutes, seconds } = calculateCounter(startAtTime);
                if (minutes >= 0 && seconds >= 0) {
                    setCounter(`${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds}`)
                } else {
                    setCounter("00:00")
                    if (status === 1) {
                        endAuction(data)
                    }
                    clearInterval(intervalId.current)
                }
            }, 1000)
            return () => clearInterval(intervalId.current)
        }
    }, [startAtTime])

    useEffect(() => {
        if (data && status === 1) {
            checkIfAuctionIsInFirebase(data.id);
            return () => clearInterval(unjoinedAuctionIntervalId.current);
        }
    }, [data, status])

    return (
        <article className="bidders__auction h-100 d-flex flex-column">
            <div className="flex-fill">
                <h3 className={`bidders__auction__max-length green-text text-center fw-bold fsize-8`}>{data?.name}</h3>
                <h3 className={`bidders__auction__max-length main-dark-text text-center fw-600 fsize-11 mb-5`}>{data?.type}</h3>
                {status !== 3 ? (
                    <ul className="bidders__auction__info-list d-flex ps-0 flex-column align-items-center mt-4 mx-auto">
                        <li className="fsize-11 fw-bold mb-3">{t("auctionRequests.maxQuantityPerUser")}: {data?.maximumAuctionAmountForUserInKilo} {t("auctionRequests.kg")}</li>
                        {status === 1 ? (
                            <>
                                <li className="fsize-11 fw-bold mb-3">{t("bidders.lastPrice")}: {lastPrice} {t("notificationModal.egp")}</li>
                                <li className="fsize-11">
                                    <div className="d-inline-flex align-items-center gap-3">
                                        <span className="fw-bold">{t("bidders.endTime")}</span>
                                        <span className="bidders__auction__info-list__end-time fw-bold green-text fsize-9">{counter || unjoinedAuctionCounter}</span>
                                    </div>
                                </li>
                            </>
                        ) : (
                            ""
                        )}
                    </ul>
                ) : (
                    ""
                )}
                <div className="d-flex justify-content-center align-items-center gap-3 mb-4">
                    <h4 className="main-dark-text fw-bold fsize-9 mb-0">{t("bidders.minBidding")}</h4>
                    <span className="d-block bidders__auction__minBiddingValue fw-bold fsize-9">
                        {data?.minimumBidding} {t("notificationModal.egp")}
                    </span>
                </div>
                {status !== 3 ? (
                    <div className="d-flex justify-content-center align-items-center gap-2">
                        <img src={dateIcon} alt="" className="bidders__auction__start-date-icon img-fluid d-block" />
                        <p className="mb-0 main-dark-text fsize-11 fw-bold">{t(`bidders.${status === 1 ? "started" : "start"}`)} {displayedDate}</p>
                        {/* <p className="mb-0 main-dark-text fsize-11 fw-bold">{t(`bidders.${status === 1 ? "started" : "start"}`)} {data ? dir === "rtl" ? moment(new Date(data.startDate)).format("A hh:mm:ss - YYYY/MM/DD") : data.startDate : ""}</p> */}
                    </div>
                ) : (
                    <div className="d-flex justify-content-center align-items-center gap-3 mb-4">
                        <h4 className="main-dark-text fw-bold fsize-9 mb-0">{t("bidders.auctionPrice")}</h4>
                        <span className="d-block bidders__auction__minBiddingValue fw-bold fsize-9">
                            {data?.auctionPrice} {t("notificationModal.egp")}
                        </span>
                    </div>
                )}
                <div className="d-flex justify-content-center align-items-center gap-3 gap-xl-5 mt-5">
                    <button
                        type="button"
                        onClick={() => onView(data)}
                        className="bg-transparent cairo-family outline-none border-0 green-text shadow-none fw-bold fsize-9 text-decoration-underline"
                    >
                        {t("bidders.viewDetails")}
                    </button>
                    {status !== 3 ? (
                        status === 1 ? (
                            <Button
                                type="button"
                                onClick={() => {
                                    if (joinBtnLoading) return;
                                    if (status === 1) {
                                        onJoin(data.id, authorization.userId)
                                    }
                                }}
                                loading={joinBtnLoading && selectedAuctionId === data?.id}
                                disabled={status === 2}
                                className="bidders__auction__join-btn border-0 h-auto outline-none shadow-none fw-bold text-white fsize-9 d-flex align-items-center justify-content-center gap-2"
                            >
                                <span className="cairo-family">{t("bidders.join")}</span>
                            </Button>
                        ) : (
                            <Button
                                type="button"
                                onClick={() => {
                                    if (!data.couldAddAmount) return;
                                    onParticipate()
                                }}
                                disabled={!data?.couldAddAmount}
                                className="bidders__auction__join-btn border-0 h-auto outline-none shadow-none fw-bold text-white fsize-9 d-flex align-items-center justify-content-center gap-2"
                            >
                                <span className="cairo-family">{t("bidders.participate")}</span>
                            </Button>
                        )
                    ) : !data?.isPaid ? (
                        <Button
                            type="button"
                            onClick={() => {
                                onPay(data)
                            }}
                            className="bidders__auction__join-btn border-0 h-auto outline-none shadow-none fw-bold text-white fsize-9 d-flex align-items-center justify-content-center gap-2"
                        >
                            <span className="cairo-family">{t("auctionRequests.pay")}</span>
                        </Button>
                    ) : (
                        ""
                    )}
                </div>
            </div>
            {
                status === 3 ? (
                    <p className="dark-gray fsize-11 text-center fw-bold mb-0 mt-4 pt-2">{data?.startDate.split("-")[0]}</p>
                ) : (
                    ""
                )
            }
        </article >
    );
}

export default Auction;