import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button, Form, InputNumber } from 'antd';
import autoAnimate from '@formkit/auto-animate';
import moment from 'moment';
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { listenToAuctionChat, listenToAuctionUpdates } from "../../../../firebaseService";
import { toastError } from "../../../../helpers/toasters";
import { endAuction, updateAuctionWinner } from "../../../../network";
import db from "../../../../firebase";

// assets
import sendIcon from "../../../../assets/icons/send.png";
import auctionEnd from "../../../../assets/images/auction-end.gif";

// components
import ChatMessage from "./ChatMessage";
import WinnerModal from "./WinnerModal";
import LastMinuteCounter from "./LastMinuteCounter";
import PaymentModal from "../../PaymentModal";

const Chat = ({ auction, auctionDetails, onCloseAuction }) => {
    const [chatMessages, setChatMessages] = useState(null);
    const [lastPrice, setLastPrice] = useState(0);
    const [startAtTime, setStartAtTime] = useState("");
    const [counter, setCounter] = useState("");
    const [auctionEnded, setAuctionEnded] = useState(false);
    const [showLastMinuteCounter, setShowLastMinuteCounter] = useState(false);
    const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
    const [isWinner, setIsWinner] = useState(false);
    const [winnerUser, setWinnerUser] = useState(""); // when the winner is different user
    const { authorization } = useSelector((state) => state.auth);
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const parent = useRef(null);
    const initialValues = { bid: "" };
    const initialAuctionLength = 1800;
    const intervalId = useRef();

    const validateInput = (_, value) => {
        if (!value && value !== 0) {
            return Promise.reject(new Error(t("bidders.chat.emptyState")))
        } else if (lastPrice && value < lastPrice + auctionDetails.incrementValue) {
            return Promise.reject(new Error(t("bidders.chat.invalidPrice")))
        } else if (!lastPrice && value < auctionDetails.minimumBidding) {
            return Promise.reject(new Error(t("bidders.chat.invalidPrice")))
        }
        return Promise.resolve()
    }

    const handlePreventLetters = (e) => {
        if (
            e.key === "Backspace" ||
            e.key === "Enter" ||
            (e.key.startsWith("Arrow") && e.key !== "ArrowUp" && e.key !== "ArrowDown")
        ) {
            return;
        }
        if (/\D+/gi.test(e.key)) {
            e.preventDefault();
        }
    };

    const handleShowPaymentMethodModal = () => {
        setIsWinner(false);
        setIsPaymentModalOpen(true)
    }

    const sendAuctionWinnerInfo = (payload) => {
        updateAuctionWinner(
            payload,
            (res) => {
                if (res.success) {

                } else {
                    toastError(res.message)
                }
            },
            (res) => {
                toastError(res.message)
            }
        )
    }

    const endAuctionWithoutWinners = (auction) => {
        endAuction(
            auction.id,
            (res) => {
                if (res.success) {
                    onCloseAuction(auction)
                } else {
                    toastError(res.message)
                }
            },
            (res) => {
                toastError(res?.fail?.error)
            }
        )
    }

    const handleAuctionEnd = async () => {
        const winnerUser = chatMessages[chatMessages.length - 1];
        if (winnerUser) {
            if (winnerUser.userId === authorization.userId) {
                setTimeout(() => {
                    setIsWinner(true);
                }, 3000)

                const payload = {
                    userId: winnerUser.userId,
                    auctionId: winnerUser.auctionId,
                    auctionPrice: winnerUser.bid
                }
                sendAuctionWinnerInfo(payload)
            } else {
                setTimeout(() => {
                    onCloseAuction(auction, true)
                }, 10000)
                setWinnerUser(winnerUser.userName)
            }
        } else {
            endAuctionWithoutWinners(auction)
        }
        // update auction active state
        await setDoc(doc(db, "auctions", auction.id), { active: false }, { merge: true });
    }

    const handleSubmit = async (values) => {
        if (auctionEnded) return;
        const msgObj = {
            auctionId: auction.id,
            userId: authorization.userId,
            userName: authorization.fullName,
            bid: values.bid,
            timestamp: new Date().getTime()
        }
        try {
            form.resetFields(["bid"]);
            // add chat message
            const myCollection = collection(db, 'chatMessages');
            await addDoc(myCollection, msgObj);
            // update auction last bid and start time
            const now = new moment();
            await setDoc(doc(db, "auctions", auction.id), {
                lastBid: values.bid,
                lastBidBy: authorization.userId,
                startAt: now.format("DD/MM/YYYY - hh:mm:ss A")
            }, { merge: true });
        } catch (error) {
            toastError(error)
        }
    }

    useEffect(() => {
        if (!auction || !auctionDetails) return;
        const unsubscribeMessages = listenToAuctionChat(
            auction.id,
            (messages) => {
                setChatMessages(messages);
            })

        const unsubscribeAuctionUpdates = listenToAuctionUpdates(
            auction.id,
            (lastPrice, startAt) => {
                setLastPrice(lastPrice);
                setStartAtTime(startAt)
            })

        return () => {
            unsubscribeMessages();
            unsubscribeAuctionUpdates();
        }
    }, [auction, auctionDetails])

    useEffect(() => {
        parent.current && autoAnimate(parent.current)
    }, [parent, parent.current]);

    useEffect(() => {
        if (startAtTime) {
            clearInterval(intervalId.current)
            intervalId.current = setInterval(() => {
                const now = new moment();
                const startTime = new moment(startAtTime, "DD/MM/YYYY - hh:mm:ss A");
                const duration = now.diff(startTime, "seconds");
                const remainingTimeInSeconds = initialAuctionLength - duration;
                const minutes = parseInt(remainingTimeInSeconds / 60);
                const seconds = remainingTimeInSeconds % 60;
                if (minutes === 0) {
                    setShowLastMinuteCounter(true)
                } else if (showLastMinuteCounter) {
                    setShowLastMinuteCounter(false)
                }
                if (minutes >= 0 && seconds >= 0) {
                    setCounter(`${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds}`)
                } else {
                    clearInterval(intervalId.current)
                    setAuctionEnded(true);
                    setShowLastMinuteCounter(false);
                    handleAuctionEnd();
                }
            }, 1000)

            return () => clearInterval(intervalId.current)
        }
    }, [startAtTime])

    return (
        <div className="auction-chat__chat-holder position-relative">
            <div className="auction-chat__chat-holder__header d-flex justify-content-between align-items-center gap-2">
                <div>
                    <h2 className="fw-bold green-text fsize-8 pb-0 mb-0">{auctionDetails?.name}</h2>
                </div>
                <div className="d-flex flex-column flex-md-row align-items-center gap-2">
                    <span className="fw-bold fsize-12 dark-gray">{t("bidders.endTime")}</span>
                    <span
                        className={`auction-chat__chat-holder__header__time-counter auction-chat__chat-holder__header__time-counter--${+counter?.split(":")[0] >= 20 ? "green" : +counter?.split(":")[0] >= 10 ? "yellow" : "red"} d-inline-block fw-bold fsize-11`}
                    >
                        {counter}
                    </span>
                </div>
            </div>
            <div className="position-relative overflow-hidden">
                <div className={`auction-chat__chat-holder__body d-flex flex-column-reverse ${chatMessages?.length === 0 ? "justify-content-center" : ""}`}>
                    {winnerUser && (
                        <p className="mt-3 green-text text-center fw-bold fsize-11">{`${winnerUser} ${t("bidders.chat.winnerOfAuction")}`}</p>
                    )}
                    <div
                        ref={parent}
                        className={`d-flex flex-column`}
                    >
                        {chatMessages?.length > 0 ? (
                            chatMessages.map((msg) => <ChatMessage key={msg.id} data={msg} />)
                        ) : (
                            <p className="auction-chat__chat-holder__body__empty-msg fw-bold fsize-6 align-self-center">
                                {t("bidders.emptyChatMsg")}
                            </p>
                        )}
                    </div>
                </div>
                <div className="auction-chat__chat-holder__footer">
                    <Form
                        form={form}
                        onFinish={handleSubmit}
                        initialValues={initialValues}
                        className="d-flex gap-3"
                    >
                        <Form.Item
                            name="bid"
                            rules={[{ validator: validateInput }]}
                            className="flex-fill d-block"
                        >
                            <InputNumber
                                keyboard={false}
                                controls={false}
                                className="d-block w-100 fw-bold fsize-11 text--natural-black"
                                onKeyDown={handlePreventLetters}
                                placeholder={t("bidders.writeHere")}
                                disabled={auctionEnded}
                            />
                        </Form.Item>
                        <Button
                            htmlType="submit"
                            disabled={auctionEnded}
                            className="auction-chat__chat-holder__footer__submit-btn d-flex justify-content-center align-items-center border-0 outline-none shadow-none"
                        >
                            <img src={sendIcon} alt="Submit icon" className="d-block img-fluid" />
                        </Button>
                    </Form>
                </div>
                <LastMinuteCounter show={showLastMinuteCounter} counter={counter} />
            </div>
            <img
                src={auctionEnd}
                alt=""
                className={`auction-chat__chat-holder__auction-end-img ${auctionEnded ? "auction-chat__chat-holder__auction-end-img--show" : ""} d-block position-absolute top-50 start-50 translate-middle`}
            />
            <WinnerModal open={isWinner} onShowPaymentMethod={handleShowPaymentMethodModal} />
            <PaymentModal
                open={isPaymentModalOpen}
                auctionPrice={lastPrice}
                auction={auctionDetails}
                closeble={false}
                alreadyWon={false}
                onCloseAuction={() => onCloseAuction(auction, true)}
            />
        </div>
    );
}

export default Chat;