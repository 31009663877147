import { useEffect, useRef, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button, Modal } from "antd";
import moment from "moment";
import { listenToAuctionUpdates } from "../../firebaseService";
import db from "../../firebase";

// assets
import dateIcon from "../../assets/icons/auction-date-icon.svg"

const ViewAuctionDetails = ({
    open,
    onCancel,
    auction,
    dir,
    status,
    onJoin,
    endAuction
}) => {
    const [startAtTime, setStartAtTime] = useState("");
    const [lastPrice, setLastPrice] = useState(0);
    const [counter, setCounter] = useState("");
    const [loading, setLoading] = useState(false)
    const [unjoinedAuctionCounter, setUnjoinedAuctionCounter] = useState("");
    const [displayedDate, setDisplayedDate] = useState("");
    const { authorization } = useSelector((state) => state.auth);
    const intervalId = useRef();
    const unjoinedAuctionIntervalId = useRef();
    const { t } = useTranslation();
    const initialAuctionLength = 1800;

    const calculateCounter = (startAtTime) => {
        const now = new moment();
        const startTime = new moment(startAtTime, "DD/MM/YYYY - hh:mm:ss A");
        const duration = now.diff(startTime, "seconds");
        const remainingTimeInSeconds = initialAuctionLength - duration;
        const minutes = parseInt(remainingTimeInSeconds / 60);
        const seconds = remainingTimeInSeconds % 60;
        return { minutes, seconds }
    }

    const checkIfAuctionIsInFirebase = async (id) => {
        const docRef = doc(db, "auctions", id)
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) {
            unjoinedAuctionIntervalId.current = setInterval(() => {
                const { minutes, seconds } = calculateCounter(auction.startDate);
                if (minutes >= 0 && seconds >= 0) {
                    setUnjoinedAuctionCounter(`${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds}`)
                } else {
                    setUnjoinedAuctionCounter("00:00")
                    endAuction(auction)
                    clearInterval(intervalId)
                }
            }, 1000)
        }
    }

    useEffect(() => {
        if (auction && dir) {
            let formattedDate = "";
            if (dir === "ltr") {
                formattedDate = auction.startDate
            } else {
                const dateArr = auction.startDate.split(" - ");
                const reversedDatePart = dateArr[0].split("/").reverse().join("/")
                formattedDate=`${reversedDatePart} - ${dateArr[1]}`
            }
            setDisplayedDate(formattedDate)
        }
    }, [auction, dir])

    useEffect(() => {
        if (!auction && status === 1) return;

        const unsubscribe = listenToAuctionUpdates(
            auction.id,
            (lastPrice, startAt) => {
                setLastPrice(lastPrice);
                setStartAtTime(startAt);
                clearInterval(unjoinedAuctionIntervalId.current)
            })

        return () => {
            unsubscribe();
        }
    }, [auction, status])

    useEffect(() => {
        if (startAtTime) {
            clearInterval(intervalId.current)
            intervalId.current = setInterval(() => {
                const { minutes, seconds } = calculateCounter(startAtTime);
                if (minutes >= 0 && seconds >= 0) {
                    setCounter(`${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds}`)
                } else {
                    setCounter("00:00")
                    // endAuction(auction)
                    clearInterval(intervalId.current)
                }
            }, 1000)
            return () => clearInterval(intervalId.current)
        }
    }, [startAtTime])

    useEffect(() => {
        if (auction && status === 1) {
            checkIfAuctionIsInFirebase(auction.id);
            return () => clearInterval(unjoinedAuctionIntervalId.current);
        }
    }, [auction, status])

    return (
        <Modal
            centered
            width={window.innerWidth >= 1440 ? "60%" : window.innerWidth >= 992 ? "70%" : "90%"}
            destroyOnClose={true}
            className="view-auction-details-modal"
            open={open}
            footer={false}
            closable={true}
            onCancel={onCancel}
            title={<h1 dir={dir} className="green-text text-center fsize-5 fw-bold">{t("bidders.auctionDetails")}</h1>}
        >
            <div dir={dir}>
                <p className="green-text text-center fw-bold fsize-5 pt-5 mb-2">{auction?.name}</p>
                <p className={`main-dark-text text-center fw-600 fsize-11 mb-4`}>{auction?.type}</p>
                {status !== 3 ? (
                    <ul className="bidders__auction__info-list mb-4 ps-0 d-flex flex-column align-items-center mx-auto">
                        <li className="fsize-11 fw-bold mb-3">{t("auctionRequests.maxQuantityPerUser")}: {auction?.maximumAuctionAmountForUserInKilo} {t("auctionRequests.kg")}</li>
                        {status === 1 ? (
                            <>
                                <li className="fsize-11 fw-bold mb-3">{t("bidders.lastPrice")}: {lastPrice} {t("notificationModal.egp")}</li>
                                <li className="fsize-11">
                                    <div className="d-inline-flex align-items-center gap-3">
                                        <span className="fw-bold">{t("bidders.endTime")}</span>
                                        <span className="bidders__auction__info-list__end-time fw-bold green-text fsize-9">{counter || unjoinedAuctionCounter}</span>
                                    </div>
                                </li>
                            </>
                        ) : (
                            ""
                        )}
                    </ul>
                ) : (
                    ""
                )}
                <div className="d-flex justify-content-center align-items-center gap-3 mb-4">
                    <p className="main-dark-text fw-bold fsize-6 mb-0">{t("bidders.minBidding")}</p>
                    <span className="d-block bidders__auction__minBiddingValue fw-bold fsize-6">
                        {auction?.minimumBidding} {t("notificationModal.egp")}
                    </span>
                </div>
                {status !== 3 ? (
                    <div className="d-flex justify-content-center align-items-center gap-3 mb-4">
                        <p className="main-dark-text fw-bold fsize-6 mb-0">{t("bidders.incrementValue")}</p>
                        <span className="d-block bidders__auction__minBiddingValue fw-bold fsize-6">
                            {auction?.incrementValue} {t("notificationModal.egp")}
                        </span>
                    </div>
                ) : (
                    ""
                )}
                {status !== 3 ? (
                    <div className="d-flex justify-content-center align-items-center gap-2 mb-4">
                        <img src={dateIcon} alt="" className="bidders__auction__start-date-icon img-fluid d-block" />
                        <p className="mb-0 main-dark-text fsize-6 fw-bold">
                            {t(`bidders.${status === 1 ? "started" : "start"}`)} {displayedDate}
                        </p>
                    </div>
                ) : (
                    <div className="d-flex justify-content-center align-items-center gap-3 mb-4">
                        <p className="main-dark-text fw-bold fsize-6 mb-0">{t("bidders.lastPrice")}</p>
                        <span className="d-block bidders__auction__minBiddingValue fw-bold fsize-6">
                            {auction?.auctionPrice} {t("notificationModal.egp")}
                        </span>
                    </div>
                )}
                {status !== 3 ? (
                    <Button
                        type="button"
                        disabled={status === 2}
                        onClick={() => {
                            if (status === 1) {
                                setLoading(true)
                                onJoin(auction.id, authorization.userId)
                            }
                        }}
                        loading={loading}
                        className="view-auction-details-modal__join-btn h-auto d-block border-0 outline-none shadow-none fw-bold fsize-5 text-center mx-auto mt-5"
                    >
                        {t("bidders.join")}
                    </Button>
                ) : (
                    ""
                )}
            </div>
        </Modal>
    );
}

export default ViewAuctionDetails;