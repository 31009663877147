import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Modal, Radio } from "antd";
import { useSelector } from "react-redux";
import { getPaymentTypesApi, placeOrderForAuction } from "../../network";
import { toastError, toastSuccess } from "../../helpers/toasters";
import { doc, getDoc, setDoc } from "firebase/firestore";
import db from "../../firebase";
import { Navigate } from "react-router-dom";
import { ROUTES } from "../../constants/_routes";

const PaymentModal = ({
    open,
    auction,
    auctionPrice,
    onCloseAuction,
    closeble,
    alreadyWon,
    onClose
}) => {
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const { authorization } = useSelector((state) => state.auth);
    const { dir } = useSelector((state) => state.language);
    const { t } = useTranslation();
    const initialValues = {
        paymentMethod: 1
    }

    const updateUserJoinedAuctionInFB = async (auctionId) => {
        const docRef = doc(db, "users", authorization.userId)
        const docSnap = await getDoc(docRef);
        // updated users joined auctions
        let joinedAuctionsArr = [];
        joinedAuctionsArr = docSnap.data().joinedAuctions.filter((obj) => obj.id === auctionId)
        await setDoc(doc(db, "users", authorization.userId), {
            joinedAuctions: joinedAuctionsArr
        }, { merge: true });
    }

    const handleFinish = (values) => {
        if (submitting || !paymentMethods) return;
        setSubmitting(true)

        const payload = {
            paymentTypeId: values.paymentMethod,
            // mobile: string,
            auctionId: auction.id,
            userId: authorization.userId
        }

        placeOrderForAuction(
            payload,
            (res) => {
                if (res.success) {
                    if (values.paymentMethod === 1) {
                        toastSuccess(res.message)
                        if (!alreadyWon) {
                            onCloseAuction()
                        } else {
                            onClose()
                        }
                    } else {
                        if (!alreadyWon) {
                            updateUserJoinedAuctionInFB(auction.id)
                        }
                        // if (values.paymentMethod === 2) {
                        // Navigate(ROUTES.PAYMOB, {
                        //   state: { paymentURL: res?.data?.paymentURL },
                        // });
                        // } else
                        if (values.paymentMethod === 2) {
                            window.location.href = res.data.paymentURL;
                        }
                    }
                } else {
                    toastError(res.message)
                }
            },
            (res) => {
                toastError(res.message)
            }
        )
    }

    useEffect(() => {
        getPaymentTypesApi(
            (res) => {
                if (res.success) {
                    setPaymentMethods(res.data);
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                toastError(res.message);
            }
        );
    }, [])

    return (
        <Modal
            centered
            width={window.innerWidth >= 1440 ? "60%" : window.innerWidth >= 992 ? "70%" : "90%"}
            destroyOnClose={true}
            className="view-auction-details-modal payment-method-modal"
            open={open}
            footer={false}
            closable={closeble}
            onCancel={onClose}
            maskClosable={closeble}
            title={<h1 dir={dir} className="green-text text-center fsize-7 fw-bold">{t("auctionRequests.paymentMethod")}</h1>}
        >
            <div dir={dir} className="pt-5">
                <div className="d-flex justify-content-center align-items-center gap-3 mb-4">
                    <p className="main-dark-text fw-bold fsize-6 mb-0">{t("auctionRequests.auctionPrice")}</p>
                    <span className="d-block bidders__auction__minBiddingValue fw-bold fsize-6">
                        {auctionPrice} {t("notificationModal.egp")}
                    </span>
                </div>
                <div className="d-flex justify-content-center align-items-center gap-3 mb-5">
                    <p className="main-dark-text fw-bold fsize-6 mb-0">{t("auctionRequests.auctionQuantity")}</p>
                    <span className="d-block bidders__auction__minBiddingValue fw-bold fsize-6">
                        {auction?.availableAmount} {t("auctionRequests.kg")}
                    </span>
                </div>
                <p className="text-center fw-bold fsize-8 text--secondary-8">{t("auctionRequests.selectPaymentMethod")}</p>
                <Form initialValues={initialValues} onFinish={handleFinish}>
                    <Form.Item
                        name="paymentMethod"
                        rules={[
                            {
                                required: true,
                                message: t("auctionRequests.requiredPaymentMethod")
                            }
                        ]}
                    >
                        <Radio.Group name="paymentMethod" className="d-flex justify-content-center align-items-center">
                            {paymentMethods.map((method) => (
                                <Radio
                                    key={method.id}
                                    value={method.id}
                                    className="payment-method-modal__payment-method-radio"
                                >
                                    <span className="fw-bold fsize-6 main-dark-text">
                                        {method.name}
                                    </span>
                                </Radio>
                            ))}
                        </Radio.Group>
                    </Form.Item>
                    <Button
                        htmlType="submit"
                        disabled={!paymentMethods}
                        loading={submitting}
                        className="view-auction-details-modal__join-btn d-block border-0 outline-none shadow-none text-white fw-bold fsize-5 text-center mx-auto mt-5 h-auto"
                    >
                        {t("auctionRequests.pay")}
                    </Button>
                </Form>
            </div>
        </Modal>
    );
}

export default PaymentModal;