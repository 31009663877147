import { useTranslation } from "react-i18next";
import { Radio } from "antd";

const AuctionsFilter = ({ active, onChange }) => {
    const { t } = useTranslation();
    const filters = [
        { label: "liveAuctions", value: 1 },
        { label: "upcomingAuctions", value: 2 },
        { label: "oldAuctions", value: 3 }
    ]

    return (
        <Radio.Group onChange={onChange} value={active}>
            {filters.map((filter) =>
                <Radio
                    key={filter.value}
                    value={filter.value}
                    className="bidders__filters-radio-holder__radio-btn text--secondary-4 fw-bold fsize-11"
                >
                    {t(`bidders.${filter.label}`)}
                </Radio>
            )}
        </Radio.Group>
    );
}

export default AuctionsFilter;