import { useEffect, useState } from "react";
import { Button, Col, Form, Input, InputNumber, Modal, Row, Select } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  getAllItemsApi,
  getAllUnitsApi,
  getOrderStatusApi,
  getPaymentTypesApi,
  placeOrderApi,
  getLuckyHoursItem
} from "../../network";
import { toastError, toastSuccess } from "../../helpers/toasters";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/_routes";
import flagIcon from "../../assets/icons/egypt-flag.svg";
import { validatePhoneNumber } from "../../helpers/helpfulFunctions";
// import { doc, increment, updateDoc } from "firebase/firestore";
// import db from "../../firebase";

function ItemModal({ isModalOpen, onCancel, selectedItem, onNewData, luckyHours }) {
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [quantity, setQuantity] = useState(null);
  const [unitId, setUnitId] = useState(null);
  const [units, setUnits] = useState([]);
  const [showPhoneInput, setShowPhoneInput] = useState(false);
  const [totalQuantityError, setTotalQuantityError] = useState("");
  const [orders, setOrders] = useState([]);
  const [payments, setPayments] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState("");
  const [withCredit, setWithCredit] = useState(false);
  const [updatedPrice, setUpdatedPrice] = useState("");
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const { dir } = useSelector((state) => state.language);
  const [newItemsData, setNewItemsData] = useState([]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    fetchAllDataFromServer();
  }, []);

  useEffect(() => {
    if (quantity && unitId && selectedItem) {
    } //eslint-disable-next-line
  }, [quantity, unitId]);

  const fetchAllDataFromServer = () => {
    getAllUnitsApi(
      (success) => {
        if (success?.success) {
          setUnits(success.data);
        } else {
          toastError(success.message);
        }
      },
      (fail) => { }
    );
    getOrderStatusApi(
      (success) => {
        if (success.success) {
          setOrders(success.data);
        } else {
          toastError(success.message);
        }
      },
      (fail) => {
        toastError(fail.message);
      }
    );
    // getPaymentTypesApi(
    //   (success) => {
    //     if (success.success) {
    //       setPayments(success.data);
    //     } else {
    //       toastError(success.message);
    //     }
    //   },
    //   (fail) => {
    //     toastError(fail.message);
    //   }
    // );
  };

  const handleOnCancel = (renderItems = false) => {
    setIsModalLoading(false);
    onCancel(renderItems);
  };

  const continuePurchasing = (values) => {
    let payload = {
      paymentTypeId: values.paymentId,
      orderTypeId: values.orderId,
      payItemRequest: {
        itemId: selectedItem.itemId,
        unitId: 1,
        // unitId: values?.unitId,
        quantity: totalQuantity ? +totalQuantity : +values.quantity,
      },
    };
    if (showPhoneInput) {
      payload.mobile = values.phone
    }
    placeOrderApi(
      payload,
      (success) => {
        if (success.success) {
          setIsModalLoading(false);
          if (success.data?.paymentTypeId === 2) {
            navigate(ROUTES.PAYMOB, {
              state: { paymentURL: success?.data?.paymentURL },
            });
          }
          else if (success.data.paymentTypeId === 4) {
            window.location.href = success.data.paymentURL;
          }
          else {
            // updateDoc(doc(db, `admin`, "notifications"), {
            //   unReadNotificationsCount: increment(1),
            // });
            toastSuccess(success.message);
            handleOnCancel(true);
          }
        } else {
          setIsModalLoading(false);
          toastError(success.message);
        }
      },
      (fail) => {
        setIsModalLoading(false);
        toastError(fail.message);
      }
    );
  }

  const handleOnFinish = (values) => {
    if (values && selectedItem && !totalQuantityError) {
      setIsModalLoading(true);
      if (luckyHours) {
        getLuckyHoursItem(
          (res) => {
            if (res.success) {
              const oldPrice = selectedItem.itemUnits[0].price;
              let newPrice;
              const purchasedItem = res.data.find((ele) => ele.itemId === selectedItem.itemId)
              if (purchasedItem) {
                newPrice = purchasedItem.itemUnits[0].price;
                if (newPrice === oldPrice) {
                  continuePurchasing(values)
                } else {
                  setNewItemsData(res.data)
                  setUpdatedPrice(+newPrice)
                  setConfirmModalOpen(true)
                  setIsModalLoading(false)
                }
              } else {
                continuePurchasing(values)
              }
            } else {
              setIsModalLoading(false)
              toastError(res.message);
            }
          },
          (fail) => {
            setIsModalLoading(false)
            toastError(fail.message);
          }
        );
      } else {
        getAllItemsApi(
          (res) => {
            if (res.success) {
              const oldPrice = selectedItem.itemUnits[0].price;
              let newPrice;
              const purchasedItem = res.data.find((ele) => ele.itemId === selectedItem.itemId)
              if (purchasedItem) {
                newPrice = purchasedItem.itemUnits[0].price;
                if (newPrice === oldPrice) {
                  continuePurchasing(values)
                } else {
                  setNewItemsData(res.data)
                  setUpdatedPrice(+newPrice)
                  setConfirmModalOpen(true)
                  setIsModalLoading(false)
                }
              } else {
                continuePurchasing(values)
              }
            } else {
              setIsModalLoading(false)
              toastError(res.message);
            }
          },
          (fail) => {
            setIsModalLoading(false)
            toastError(fail.message);
          }
        );

      }

    }
  };

  // const handleQuantityOnChange = (e) => {
  //   setQuantity(e?.target?.value);
  // };
  // const handleUnitOnChange = (e) => {
  //   setUnitId(e?.target?.value);
  // };

  const calculatePrice = (quantity, withCreditCard, newPrice) => {
    const price = newPrice || +selectedItem.itemUnits[0].price;
    let updatedTotalPrice = price * quantity;
    if (withCreditCard === 2 || withCreditCard === 4) {
      updatedTotalPrice += (updatedTotalPrice * 2.5) / 100;
    }
    setTotalPrice(updatedTotalPrice.toFixed(2));
  };

  const handlePaymentType = (value) => {
    if (value === 4) {
      setShowPhoneInput(true)
    } else {
      setShowPhoneInput(false)
    }
    setWithCredit(value);
    calculatePrice(totalQuantity, value);
  };

  const validateQuantityInput = (_, value) => {
    if (value === 0 || (value && !/^\d+$/.test(value))) {
      return Promise.reject(new Error(t("home.itemModal.invalidQuantity")));
    }
    const totalQuantity = value * selectedItem.unitValue;
    if (value) {
      setTotalQuantity(totalQuantity);
    } else {
      setTotalQuantity("");
    }
    if (totalQuantity > selectedItem?.quantityPerKilo) {
      if (selectedItem.unitValue === 1) {
        return Promise.reject(new Error(t("home.itemModal.quantityError")));
      } else {
        setTotalQuantityError("Quantity not available in store");
      }
    } else {
      setTotalQuantityError("");
    }
    calculatePrice(totalQuantity, form.getFieldValue("paymentId"));
    return Promise.resolve();
  };

  const handleOrderTypeChange = (value) => {
    form.setFieldsValue({
      paymentId: "",
    });
    setPayments([]);
    setShowPhoneInput(false)
    setWithCredit(false)
    getPaymentTypesApi(
      (success) => {
        if (success.success) {
          if (value === 1) {
            let filteredData = success.data.filter((p) => p?.id === 1);
            setPayments(filteredData);
          } else {
            setPayments(success.data);
          }
        } else {
          toastError(success.message);
        }
      },
      (fail) => {
        toastError(fail.message);
      }
    );
    // const paymentsClone = structuredClone(payments);
    // if (value === 1) {
    //   paymentsClone[1].disabled = true;
    // } else {
    //   paymentsClone[1].disabled = false;
    // }
    // setPayments(paymentsClone);
  };

  const validatePhone = (_, value) => {
    const phoneRegex = /^[\d]+$/;

    if (!value) {
      return Promise.reject("");
    } else if (!phoneRegex.test(value)) {
      return Promise.reject(new Error(t("home.itemModal.invalidWallet")));
    } else {
      const isValid = validatePhoneNumber(value);
      if (isValid) {
        return Promise.resolve();
      } else {
        return Promise.reject(new Error(t("home.itemModal.invalidWallet")));
      }
    }
  };

  const handlePreventLetters = (e) => {
    if (e.key === "Backspace" || (e.key.startsWith("Arrow") && e.key !== "ArrowUp" && e.key !== "ArrowDown")) return;
    if (/\D+/gi.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleConfirmNewPrice = () => {
    setConfirmModalOpen(false)
    onNewData(newItemsData)
    const quantity = totalQuantity ? +totalQuantity : +form.getFieldValue("quantity")
    calculatePrice(quantity, form.getFieldValue("paymentId"), updatedPrice)
  }

  return (
    <>
      <Modal
        centered
        width={window.innerWidth >= 1440 ? "60%" : "90%"}
        destroyOnClose={true}
        open={isModalOpen}
        footer={false}
        closable={true}
        onCancel={handleOnCancel}
      >
        <Form
          size={"large"}
          dir={dir}
          form={form}
          className="payment-details-form"
          onFinish={handleOnFinish}
          initialValues={{
            // unitId: "",
            orderId: undefined,
            paymentId: undefined,
            quantity: null,
            phone: ""
          }}
          layout="vertical"
        >
          <Row justify={"center"}>
            <Col span={24}>
              <h2 className="green-text fsize-4 available-quantity mb-4 wd-100 text-center">{`${t(
                "home.itemModal.available"
              )} : ${selectedItem?.quantityPerKilo} ${selectedItem?.quantityPerKilo > 1
                ? t("home.itemModal.kilos")
                : t("home.itemModal.kilo")
                }`}</h2>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              {/* here we will add the remain quantity of the item  */}
              {/* <Form.Item
                label={"Select unit"}
                name={"unitId"}
                className=" "
                rules={[{ required: true, message: "Please select unit type" }]}
              >
                <Select
                  selectName="unitId"
                  className=""
                  placeholder="Select unit"
                  style={{
                    color: "#9A9FA5",
                    width: "100%",
                  }}
                  allowClear
                  defaultValue={undefined}
                  onChange={handleUnitOnChange}
                >
                  {units?.map((unit, index) => (
                    <Select.Option value={unit?.id} key={index}>
                      {unit?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item> */}
            </Col>
            <Col span={24} className="mb-xxl-4">
              <Form.Item
                label={t("home.itemModal.type")}
                name={"orderId"}
                className="fsize-7"
                rules={[
                  { required: true, message: t("home.itemModal.requiredType") },
                ]}
              >
                <Select
                  placeholder={t("home.itemModal.type")}
                  style={{
                    color: "#9A9FA5",
                    width: "100%",
                  }}
                  allowClear
                  onChange={handleOrderTypeChange}
                >
                  {orders?.map((unit, index) => (
                    <Select.Option
                      className={dir === "rtl" ? "text-end" : "text-start"}
                      value={unit?.id}
                      key={index}
                    >
                      {unit.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} className="mb-xxl-4">
              <Form.Item
                label={
                  selectedItem?.unitValue === 1
                    ? t("home.itemModal.enterQuantity")
                    : `${t("home.itemModal.enterUnit")} ( ${t("home.itemModal.unit")} = ${selectedItem?.unitValue
                    } ${t("home.itemModal.quantity")} )`
                }
                name="quantity"
                rules={[
                  {
                    required: true,
                    message: t("home.itemModal.requiredQuantity"),
                  },
                  { validator: validateQuantityInput },
                ]}
              >
                <InputNumber
                  type="number"
                  keyboard={false}
                  controls={false}
                  className="d-block w-100 payment-details-form__price-input"
                  // max={selectedItem?.quantityPerKilo}
                  onKeyDown={handlePreventLetters}
                  placeholder={selectedItem?.unitValue === 1
                    ? t("home.itemModal.quantity")
                    : t("home.itemModal.unit")}
                  onChange={(e) => {
                    form.validateFields(["quantity"]);
                  }}
                // className="main-input--number d-block w-100 h-100 border-0 outline-0 shadow-none"
                />
                {/* <Input
                type="text"
                min={1}
                max={selectedItem?.quantityPerKilo}
                className=""
                placeholder={
                  selectedItem?.unitValue === 1
                    ? t("home.itemModal.quantity")
                    : t("home.itemModal.unit")
                }
                onChange={(e) => {
                  form.validateFields(["quantity"]);
                }}
              /> */}
              </Form.Item>
            </Col>
            {totalQuantity &&
              selectedItem?.unitValue !== 1 &&
              selectedItem?.unitValue > 0 ? (
              <Col span={24}>
                <Form.Item label={t("home.itemModal.totalQuantity")} disabled>
                  <p
                    className={`${totalQuantityError ? "ant-input-status-error" : ""
                      } total-price-input ant-input ant-input-lg`}
                  >
                    {+totalQuantity.toFixed(3)}
                  </p>
                  {totalQuantityError && (
                    <p className="mb-0 ant-form-item-explain-error">
                      {t("home.itemModal.quantityError")}
                    </p>
                  )}
                </Form.Item>
              </Col>
            ) : (
              ""
            )}
            <Col span={24}>
              <Form.Item
                label={t("home.itemModal.payment")}
                name={"paymentId"}
                rules={[
                  {
                    required: true,
                    message: t("home.itemModal.requiredPayment"),
                  },
                ]}
              >
                <Select
                  placeholder={t("home.itemModal.payment")}
                  style={{
                    color: "#9A9FA5",
                    width: "100%",
                  }}
                  onChange={handlePaymentType}
                  allowClear
                >
                  {payments.map((unit, index) => (
                    <Select.Option
                      className={dir === "rtl" ? "text-end" : "text-start"}
                      value={unit?.id}
                      key={index}
                    >
                      {unit.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {showPhoneInput ?
              <Col span={24} className="mb-xxl-4">
                <Form.Item
                  label={t("home.itemModal.walletNumber")}
                  name="phone"
                  rules={[
                    { required: true, message: t("home.itemModal.requiredWallet") },
                    { validator: validatePhone }
                  ]}
                >
                  <Input
                    type="text"
                    className="w-100 payment-details-form__phone-input"
                    onKeyDown={handlePreventLetters}
                    placeholder="xxxxxxxxxxxx"
                    prefix={
                      <div className="d-inline-flex align-items-center gap-1">
                        <img src={flagIcon} alt="" className="payment-details-form__phone-input__prefix-icon" />
                        <span className="payment-details-form__phone-input__prefix-text fw-500">+20</span>
                      </div>
                    }
                  />
                </Form.Item>
              </Col>
              : ""}
            <Col span={24}>
              <Form.Item label={t("home.itemModal.totalPrice")} disabled>
                <p className="total-price-input ant-input ant-input-lg">
                  {totalPrice} {t("home.itemModal.priceUnit")}
                </p>
                {(withCredit === 2 || withCredit === 4) && (
                  <p className="mt-1 text-danger">
                    *{" "}
                    {t("home.itemModal.taxes")}
                  </p>
                )}
              </Form.Item>
            </Col>
            {/* <Col span={24}>
              {" "}
              <Form.Item
                label={"Enter quantity"}
                className="  "
                name="quantity"
                rules={[
                  {
                    required: true,
                    message: "Please, Enter quantity",
                  },
                ]}
              >
                <Input
                  type="number"
                  min={1}
                  className=""
                  placeholder="Enter quantity"
                  onChange={handleQuantityOnChange}
                />
              </Form.Item>
            </Col> */}
            {/* <Col span={24}><h2>{`Total :${}`}</h2></Col> */}
            <Col span={24}>
              {" "}
              <Row className="" justify={"center"}>
                <Button
                  loading={isModalLoading}
                  className="contact-us__form-btn fsize-8 text-center"
                  htmlType="submit"
                >
                  {t("home.buyItem")}
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        centered
        destroyOnClose={true}
        open={confirmModalOpen}
        footer={false}
        closable={false}
      >
        <div dir={dir}>
          <p className="mb-3">
            {t("home.itemModal.productPriceChanged")}{" "}<span className="fw-bold">{`${updatedPrice} ${t("home.itemModal.priceUnit")}`}</span>.{" "} {t("home.itemModal.pressOk")}
          </p>
          <Button className="ms-auto d-block text-white bg--main-blue" onClick={handleConfirmNewPrice}>{t("home.itemModal.ok")}</Button>
        </div>
      </Modal>
    </>
  );
}

export default ItemModal;
