import { useTranslation } from "react-i18next";

// assets
import emptyImg from "../../assets/images/buy-and-supply-balance-empty-state.png"

const BalanceEmptyState = () => {
    const { t } = useTranslation();

    return (
        <div className="buy-and-supply__empty-state buy-and-supply__empty-state--balance py-5 my-5 d-flex flex-column align-items-center">
            <img src={emptyImg} alt="" className="d-block img-fluid" />
            <p className="fsize-4 fw-bold">{t("buyAndSupply.pageIsEmpty")}</p>
            <p className="fsize-5 fw-500 mb-0">{t("buyAndSupply.checkBalanceLater")}</p>
        </div>
    );
}

export default BalanceEmptyState;