import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Spin } from "antd";
import moment from "moment";
import autoAnimate from '@formkit/auto-animate';
import { doc, getDoc, setDoc } from "firebase/firestore";
import { endAuction, getAuctions, updateAuctionWinner } from "../../network";
import { toastError } from '../../helpers/toasters';
import db from "../../firebase";

// assets
import backToAuctionsIcon from "../../assets/icons/back-to-auction-btn.png"

// components
import Auction from "./Auction";
import EmptyAuctions from "./EmptyAuctions";
import AuctionsFilter from "./AuctionFilter";
import AuctionSearch from "./AuctionSearch";
import ViewAuctionDetails from "./ViewAuctionDetails";
import PaymentModal from "./PaymentModal";
import AcceptOrRejectModal from "../Modals/AuctionRequestsDrawer/AcceptOrRejectModal";

const Bidders = () => {
    const [firstRender, setFirstRender] = useState(true);
    const [auctions, setAuctions] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [allAuctionsAreEmpty, setAllAuctionsAreEmpty] = useState(false);
    const [auctionLoading, setAuctionLoading] = useState(false)
    const [status, setStatus] = useState(1); // 1 --> live, 2 --> soon, 3 --> old
    const [searchValue, setSearchValue] = useState("");
    const [viewDetailsModalOpen, setViewDetailsModalOpen] = useState(false);
    const [selectedAuction, setSelectedAuction] = useState("");
    const [joinedAuctions, setJoinedAuctions] = useState(null);
    const [joinAuctionBtnLoading, setJoinAuctionBtnLoading] = useState(false);
    const [participateModalOpen, setParticipateModalOpen] = useState(false);
    const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
    const { authorization } = useSelector((state) => state.auth);
    const { dir } = useSelector((state) => state.language);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const parent1 = useRef(null);
    const parent2 = useRef(null);

    const handleViewDetails = (auction) => {
        setSelectedAuction(auction)
        setViewDetailsModalOpen(true)
    }

    const handleCancelViewDetails = () => {
        setSelectedAuction("")
        setViewDetailsModalOpen(false)
    }

    const handleFilterChange = (e) => {
        setStatus(e.target.value);
        setAuctions(null)
        setSearchValue("")
        setAuctionLoading(true);
        getAuctionsByStatus(e.target.value)
    }

    const handleSearch = (e) => {
        setSearchValue(e.target.value)
        const searchRegex = new RegExp(e.target.value, "ig");
        const searchResults = auctions.filter((auction) => auction.name.search(searchRegex) !== -1 || auction.startDate.search(searchRegex) !== -1);
        setFilteredData(searchResults)
    }

    const handleJoining = async (auctionId, userId) => {
        try {
            const userDocRef = doc(db, "users", userId)
            const userDocSnap = await getDoc(userDocRef);
            const userJoinedAuctions = userDocSnap.data().joinedAuctions || [];
            const auctionIndx = userJoinedAuctions.findIndex((ele) => ele.auctionId === auctionId)
            if (auctionIndx === -1) {
                userJoinedAuctions.push({ auctionId, isTabActive: true })
            } else {
                userJoinedAuctions[auctionIndx].isTabActive = true;
            }
            await setDoc(doc(db, "users", userId), {
                joinedAuctions: userJoinedAuctions,
            }, { merge: true });

            const auctionDocRef = doc(db, "auctions", auctionId)
            const auctionDocSnap = await getDoc(auctionDocRef);
            if (!auctionDocSnap.exists()) {
                const auctionObj = auctions.find((auction) => auction.id === auctionId)
                await setDoc(doc(db, "auctions", auctionId), {
                    lastBid: 0,
                    startAt: auctionObj.startDate,
                    lastBidBy: "",
                    active: true
                });
            }
            setJoinAuctionBtnLoading(false);
            navigate(`/bidders/auction?auctionId=${auctionId}`)
        } catch (error) {
            toastError(error)
        }
    }

    const checkIfUserJoinedAuctions = async (id) => {
        try {
            const docRef = doc(db, "users", id)
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const userData = docSnap.data();
                if (userData.joinedAuctions?.length > 0) {
                    setJoinedAuctions(userData.joinedAuctions.filter((ele) => ele.isTabActive));
                }
            }
        } catch (error) {
            toastError(error)
        }
    }

    const calculateCounter = (startAtTime) => {
        const initialAuctionLength = 1800;
        const now = new moment();
        const startTime = new moment(startAtTime, "DD/MM/YYYY - hh:mm:ss A");
        const duration = now.diff(startTime, "seconds");
        const remainingTimeInSeconds = initialAuctionLength - duration;
        const minutes = parseInt(remainingTimeInSeconds / 60);
        const seconds = remainingTimeInSeconds % 60;
        return { minutes, seconds }
    }

    const removeAuctionFromData = (auctionId) => {
        const updatedData = auctions.filter((ele) => ele.id !== auctionId)
        setAuctions(updatedData)
        const updatedFiltredData = filteredData.filter((ele) => ele.id !== auctionId)
        setFilteredData(updatedFiltredData)
    }

    const endOldAuction = (auctionId, removeFromData) => {
        endAuction(
            auctionId,
            (res) => {
                if (res.success) {
                    if (removeFromData) {
                        removeAuctionFromData(auctionId)
                    }
                } else {
                    toastError(res.message)
                }
            },
            (res) => {
                toastError(res?.fail?.error)
            }
        )
    }

    const sendAuctionWinnerInfo = (payload, removeFromData) => {
        updateAuctionWinner(
            payload,
            (res) => {
                if (res.success) {
                    if (removeFromData) {
                        removeAuctionFromData(payload.auctionId)
                    }
                } else {
                    toastError(res.message)
                }
            },
            (res) => {
                toastError(res.message)
            }
        )
    }

    const checkIfAuctionIsOld = async (auction, removeFromData, sendRequestIfWinner = false) => {
        const docRef = doc(db, "auctions", auction.id)
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) {
            const { minutes, seconds } = calculateCounter(auction.startDate);
            if (minutes <= 0 && seconds <= 0) {
                endOldAuction(auction.id, removeFromData)
                return true
            } else {
                await setDoc(docRef, {
                    lastBid: 0,
                    startAt: auction.startDate,
                    lastBidBy: "",
                    active: true
                });
            }
        } else {
            const auctionDataInFB = docSnap.data()
            const { minutes, seconds } = calculateCounter(docSnap.data().startAt);
            if (minutes <= 0 && seconds <= 0) {
                // if auction has a winner
                if (auctionDataInFB.lastBid) {
                    if (sendRequestIfWinner) {
                        const payload = {
                            userId: auctionDataInFB.lastBidBy,
                            auctionId: auction.id,
                            auctionPrice: auctionDataInFB.lastBid
                        }
                        sendAuctionWinnerInfo(payload, removeFromData)
                    } else if (removeFromData) {
                        removeAuctionFromData(auction.id)
                    }
                } else {
                    endOldAuction(auction.id, removeFromData)
                }
                // update user joined auctions
                const userDocRef = doc(db, "users", authorization.userId)
                const docSnap = await getDoc(userDocRef);
                if (docSnap.data().joinedAuctions) {
                    const joinedAuctionsArr = docSnap.data().joinedAuctions.filter((ele) => ele.auctionId !== auction.id);
                    await setDoc(doc(db, "users", authorization.userId), {
                        joinedAuctions: joinedAuctionsArr
                    }, { merge: true });
                    // get new joined auctions data
                    checkIfUserJoinedAuctions(authorization.userId)
                }
                // update auction active state
                await setDoc(doc(db, "auctions", auction.id), { active: false }, { merge: true });
                return true
            }
        }
        return false
    }

    const handleAuctionTimeEnded = (auction) => {
        checkIfAuctionIsOld(auction, true)
    }

    const getAuctionsByStatus = (status, allAuctionsAreEmpty, checkNextStatus = false) => {
        getAuctions(
            status,
            async (res) => {
                setAuctionLoading(false)
                if (res.success) {
                    if (res.data.length === 0 && checkNextStatus) {
                        const nextStatus = status + 1;
                        const checkNextStatus = nextStatus < 3;
                        setAllAuctionsAreEmpty(true);
                        getAuctionsByStatus(nextStatus, true, checkNextStatus)
                    } else {
                        let onlyActiveAuctions = []
                        if (status === 1) {
                            for (let i = 0; i < res.data.length; i++) {
                                const isOld = await checkIfAuctionIsOld(res.data[i], false, true)
                                if (!isOld) {
                                    if (res.data[i].availableAmount > 0) {
                                        onlyActiveAuctions.push(res.data[i])
                                    } else {
                                        endOldAuction(res.data[i].id)
                                        // update auction active state
                                        await setDoc(doc(db, "auctions", res.data[i].id), { active: false }, { merge: true });
                                    }
                                }
                            }
                        } else {
                            onlyActiveAuctions = res.data
                        }

                        // if (onlyActiveAuctions.length === 0) {
                        //     const nextStatus = status + 1;
                        //     const checkNextStatus = nextStatus < 3;
                        //     setAllAuctionsAreEmpty(true);
                        //     getAuctionsByStatus(nextStatus, true, checkNextStatus)
                        // } else {
                        setAuctions(onlyActiveAuctions)
                        setFilteredData(onlyActiveAuctions)
                        setStatus(status)
                        if (allAuctionsAreEmpty && res.data.length > 0) {
                            setAllAuctionsAreEmpty(false)
                        }
                    }
                    // }
                } else {
                    toastError(res.message)
                }
            },
            (res) => {
                setAuctionLoading(false)
                toastError(res.message)
            }
        )
    }

    useEffect(() => {
        const currentStatus = status || 1;
        /* 
         * Only check next status automatically (if no data returned from current status) 
         * when it's first render
         */
        const checkNextStatus = firstRender === undefined ? true : firstRender
        if (checkNextStatus) {
            setFirstRender(false)
        }
        getAuctionsByStatus(currentStatus, allAuctionsAreEmpty, checkNextStatus)
    }, [dir])

    useEffect(() => {
        parent1.current && autoAnimate(parent1.current)
    }, [parent1, parent1.current])

    useEffect(() => {
        parent2.current && autoAnimate(parent2.current)
    }, [parent2, parent2.current])

    useEffect(() => {
        if (authorization) {
            checkIfUserJoinedAuctions(authorization.userId)
        }
    }, [authorization])

    return (
        <section className="bidders">
            {
                auctions ? (
                    allAuctionsAreEmpty ? (
                        <EmptyAuctions allAuctions={true} />
                    ) : (
                        <div dir={dir}>
                            <div className="d-flex flex-column-reverse flex-md-row justify-content-between gap-4 gap-md-2 align-items-start">
                                <div>
                                    <h1 className="fsize-5 fw-bold green-text mb-3">{t("bidders.activeAuctions")}</h1>
                                    <div className="mb-3 bidders__filters-radio-holder">
                                        <AuctionsFilter active={status} onChange={handleFilterChange} />
                                    </div>
                                    <div className="mb-4">
                                        <AuctionSearch onChange={handleSearch} value={searchValue} />
                                    </div>
                                </div>
                                {joinedAuctions?.length > 0 ? (
                                    <Link
                                        className={`bidders__back-to-auctions-btn ${dir === "rtl" ? "bidders__back-to-auctions-btn--ar" : ""} align-self-end align-self-md-start text-white fw-bold fsize-11 text-decoration-none d-flex flex-column align-items-center gap-1`}
                                        to={`/bidders/auction?auctionId=${joinedAuctions[0].auctionId}`}
                                    >
                                        <img src={backToAuctionsIcon} alt="" className="img-fluid d-block" />
                                        <span className="text-center">{t("bidders.backToAuctions")}</span>
                                    </Link>
                                ) : (
                                    ""
                                )}
                            </div>
                            {auctionLoading ? (
                                <div className="d-flex justify-content-center align-items-center pt-5">
                                    <Spin size="large" />
                                </div>
                            ) : (
                                <div ref={parent2}>
                                    <h2 className="green-text fw-500 fsize-9 mb-4">{t("bidders.allAuctions")} ({filteredData.length})</h2>
                                    {filteredData.length > 0 ? (
                                        <div ref={parent1} className="row m-0 bidders__auctions-holder">
                                            {filteredData.map((auction, indx) => (
                                                <div key={auction.id} className={`col-md-6 px-0 ${indx % 2 === 0 ? `${dir === "rtl" ? "ps" : "pe"}-md-3` : `${dir === "rtl" ? "pe" : "ps"}-md-3`}`}>
                                                    <Auction
                                                        data={auction}
                                                        status={status}
                                                        onView={handleViewDetails}
                                                        onJoin={(auctionId, userId) => {
                                                            setJoinAuctionBtnLoading(true)
                                                            setSelectedAuction(auction)
                                                            handleJoining(auctionId, userId)
                                                        }}
                                                        dir={dir}
                                                        selectedAuctionId={selectedAuction?.id}
                                                        joinBtnLoading={joinAuctionBtnLoading}
                                                        endAuction={handleAuctionTimeEnded}
                                                        onPay={(data) => {
                                                            setSelectedAuction(data)
                                                            setIsPaymentModalOpen(true)
                                                        }}
                                                        onParticipate={() => {
                                                            setSelectedAuction({ auctionId: auction.id, ...auction })
                                                            setParticipateModalOpen(true)
                                                        }}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <EmptyAuctions search={searchValue} />
                                    )}
                                </div>
                            )}
                        </div>
                    )) : (
                    <div className="d-flex justify-content-center align-items-center pt-5">
                        <Spin size="large" />
                    </div>
                )
            }
            {viewDetailsModalOpen && (
                <ViewAuctionDetails
                    dir={dir}
                    auction={selectedAuction}
                    open={viewDetailsModalOpen}
                    onCancel={handleCancelViewDetails}
                    endAuction={handleAuctionTimeEnded}
                    onJoin={(auctionId, userId) => {
                        handleJoining(auctionId, userId)
                    }}
                    status={status}
                />
            )}
            {isPaymentModalOpen && (
                <PaymentModal
                    open={isPaymentModalOpen}
                    auctionPrice={selectedAuction?.auctionPrice}
                    auction={selectedAuction}
                    closeble={true}
                    alreadyWon={true}
                    onClose={() => {
                        setSelectedAuction(null)
                        setIsPaymentModalOpen(false)
                    }}
                />
            )}
            {participateModalOpen && (
                <AcceptOrRejectModal
                    open={participateModalOpen}
                    mode={"accept"}
                    onCancel={() => {
                        setParticipateModalOpen(false)
                        setSelectedAuction(null)
                    }}
                    selectedAuction={selectedAuction}
                    onSubmit={() => {
                        setParticipateModalOpen(false)
                        setSelectedAuction(null)
                        setAuctionLoading(true)
                        getAuctionsByStatus(status)
                    }}
                />
            )}
        </section>
    )
}

export default Bidders;