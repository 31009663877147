/**********************************************/ 
/*                  LIVE CONFIG               */ 
/**********************************************/ 
// import { initializeApp } from "firebase/app";
// import { getFirestore } from "firebase/firestore";

// const firebaseConfig = {
//   apiKey: "AIzaSyCW7tLO10TUngkWDxaZqyXRMPDwXIEhid4",
//   authDomain: "recycling-33b80.firebaseapp.com",
//   projectId: "recycling-33b80",
//   storageBucket: "recycling-33b80.appspot.com",
//   messagingSenderId: "638393445867",
//   appId: "1:638393445867:web:547f6cb1fe36b55116bdae",
// };
// const app = initializeApp(firebaseConfig);
// const db = getFirestore(app);

// export default db;

/**********************************************/ 
/*                  TEST CONFIG               */ 
/**********************************************/ 
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyA_OlsQZjl4LphquNVSQv7IkSZLsO-M1QA",
  authDomain: "greenmatterweb-test.firebaseapp.com",
  projectId: "greenmatterweb-test",
  storageBucket: "greenmatterweb-test.appspot.com",
  messagingSenderId: "734944809970",
  appId: "1:734944809970:web:d6ea12c5ad31a35b39916b",
  measurementId: "G-63K880YBYV"
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db;


// import { initializeApp } from "firebase/app";
// import { getFirestore } from "firebase/firestore";
// import { getAuth } from "firebase/auth";
// import { getStorage } from "firebase/storage";

// const firebaseConfig = {
//   apiKey: "AIzaSyCW7tLO10TUngkWDxaZqyXRMPDwXIEhid4",
//   authDomain: "recycling-33b80.firebaseapp.com",
//   projectId: "recycling-33b80",
//   storageBucket: "recycling-33b80.appspot.com",
//   messagingSenderId: "638393445867",
//   appId: "1:638393445867:web:547f6cb1fe36b55116bdae",
// };

// // Initialize Firebase
// export const app = initializeApp(firebaseConfig);

// // Initialize Cloud Firestore and get a reference to the service
// export const db = getFirestore(app);
// export const auth = getAuth();
// export const storage = getStorage();

// // import { initializeApp } from "firebase/app";
// // import { getFirestore } from "firebase/firestore";

// // const firebaseConfig = {
// //   apiKey: "AIzaSyCW7tLO10TUngkWDxaZqyXRMPDwXIEhid4",
// //   authDomain: "recycling-33b80.firebaseapp.com",
// //   projectId: "recycling-33b80",
// //   storageBucket: "recycling-33b80.appspot.com",
// //   messagingSenderId: "638393445867",
// //   appId: "1:638393445867:web:547f6cb1fe36b55116bdae",
// // };
// // const app = initializeApp(firebaseConfig);
// // const db = getFirestore(app);
// // export default db;

// // import { initializeApp } from "firebase/app";
// // import { getMessaging, getToken, onMessage } from "firebase/messaging";

// // const firebaseConfig = {
// //   apiKey: "AIzaSyCW7tLO10TUngkWDxaZqyXRMPDwXIEhid4",
// //   authDomain: "recycling-33b80.firebaseapp.com",
// //   projectId: "recycling-33b80",
// //   storageBucket: "recycling-33b80.appspot.com",
// //   messagingSenderId: "638393445867",
// //   appId: "1:638393445867:web:547f6cb1fe36b55116bdae",
// // };
// // const app = initializeApp(firebaseConfig);
// // const messaging = getMessaging(app);

// // /**
// //  *
// //  * if (Notification.permission !== 'granted') {
// //     Notification.requestPermission().then(permission => {
// //         if (permission === 'granted') {
// //             // Continue with notification logic
// //         }
// //     });
// // }
// //  */

// // export const requestPermissions = () => {
// //   console.log("requesting");
// //   if (Notification.permission !== "granted") {
// //     Notification.requestPermission().then((permission) => {
// //       console.log("permission", permission);
// //       if (permission === "granted") {
// //         console.log("Notification user permission granted");
// //         return getToken(messaging, {
// //           vapidKey:
// //             "BAl5EMWqtMG0lp9lir9iwOjt3cvazHm1g8S5uaW-yykzI8ueha4niVfMMM8zuG2RAcyZTmnHv3hLatgotPGfzaw",
// //         })
// //           .then((currentToken) => {
// //             if (currentToken) {
// //               console.log(currentToken);
// //               console.log("currentToken", currentToken);
// //               localStorage.setItem("fbToken", currentToken);
// //               onMessage(messaging, (payload) => {
// //                 console.log("Foreground Message received:", payload);
// //               });
// //             } else {
// //               console.log("failed to generate the app registration token");
// //             }
// //           })
// //           .catch((error) => {
// //             console.log(
// //               "An error occured while requesting to receive token",
// //               error
// //             );
// //           });
// //       } else {
// //         console.log("Permission Denied");
// //       }
// //     });
// //   }
// // };

// // // export const onMessageListener = async () => {
// // //   console.log("onMessageListener");
// // //   return new Promise((resolve) => {
// // //     onMessage(messaging, (payload) => {
// // //       console.log("payload", payload);
// // //       resolve(payload);
// // //     });
// // //   });
// // // };

// // export const onMessageListener = (callback) => {
// //   // const messaging = getMessaging();
// //   onMessage(messaging, (payload) => {
// //     console.log("Foreground Message received:", payload);
// //     callback(payload);
// //   });
// //   // console.log("unsubscribe", unsubscribe);
// //   // return unsubscribe;
// // };
