import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Row, Spin } from "antd";
import { toastError } from "../../../helpers/toasters";
import { getTermsAndConditions, signUpApi } from "../../../network";
import GlobalModal from "../GlobalModal";
import { ROUTES } from "../../../constants/_routes";
import LanguageBtn from "../../Common/LanguageBtn/LanguageBtn";
import TermAndConditionsModal from "./TermsAndConditionsModal";
import logoIcon from "../../../assets/icons/logoIcon.svg";
import flag from "../../../assets/icons/egypt-flag.svg";

function SignUp() {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [fullName, setFullName] = useState("");
  const [mobile, setMobile] = useState("");
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [nameError, setNameError] = useState("");
  const [terms, setTerms] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const { authorization } = useSelector((state) => state?.auth);
  const { dir } = useSelector((state) => state.language);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);

  useEffect(() => {
    if (authorization.token) {
      navigate("/home", { replace: true })
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  useEffect(() => {
    getTermsAndConditions(
      (success) => {
        if (success.success) {
          setTerms(success.data);
        } else {
          toastError(success.message);
        }
      },
      (fail) => {
        if (fail?.data) {
          toastError(fail.data?.message);
        } else {
          toastError();
        }
      }
    );
  }, [dir]);

  const handleOnChangeName = (e) => {
    const value = e.target.value;
    setFullName(value);
    if (!value) {
      setNameError("requiredName")
    } else if (/^[A-Za-z\s\u0621-\u064A]*$/.test(value)) {
      setNameError("");
    } else {
      setNameError("invalidName");
    }
  };

  const handleOnChangePhone = (e) => {
    const phoneRegex = /^[\d]*$/;
    if (phoneRegex.test(e.target.value)) {
      setMobile(e.target.value);
      setPhoneError("");
    } else {
      return;
    }
    if (e.target.value.length === 0) {
      setPhoneError("requiredMobile");
    } else {
      let phoneLen = 11;
      let charLen = 2;
      if (!e.target.value.startsWith("0")) {
        phoneLen = 10;
        charLen = 1;
      }
      if (
        e.target.value.length > phoneLen ||
        (e.target.value.length >= charLen && !e.target.value.startsWith(`${charLen === 2 ? "0" : ""}1`)) ||
        (e.target.value.length >= charLen + 1 &&
          !e.target.value.startsWith(`${charLen === 2 ? "0" : ""}10`) &&
          !e.target.value.startsWith(`${charLen === 2 ? "0" : ""}11`) &&
          !e.target.value.startsWith(`${charLen === 2 ? "0" : ""}12`) &&
          !e.target.value.startsWith(`${charLen === 2 ? "0" : ""}15`))
      ) {
        setPhoneError("invalidMobile");
      }
    }
  };
  const handleEmailChange = (e) => {
    const value = e.target.value
    setEmail(value)
    setEmailError(false)
    if (!value) {
      setEmailError("requiredEmail")
    } else if (!/^[A-Za-z0-9+_.-]+@(.+)$/.test(value)) {
      setEmailError("invalidEmail")
    } else {
      setEmailError("")
    }
  }
  const onCancel = () => {
    setIsModalOpen(false);
  };
  const onAction = (data) => { };

  const handleTermsConditions = (e) => {
    if (e.target.checked && termsError) {
      setTermsError(false);
    }
    setIsTermsAccepted(e.target.checked);
  };

  const onFinish = (e) => {
    e.preventDefault();
    if (!fullName) {
      setNameError("requiredName");
    }
    if (!mobile) {
      setPhoneError("requiredMobile");
    } else if (mobile.length > 11 || mobile.length < 10) {
      setPhoneError("invalidMobile");
    }
    if (!isTermsAccepted) {
      setTermsError(true);
    }
    if (!email) {
      setEmailError("requiredEmail");
    }
    if (
      fullName &&
      mobile &&
      (mobile.length === 10 || mobile.length === 11) &&
      isTermsAccepted &&
      !emailError &&
      !nameError &&
      !phoneError
    ) {
      setLoading(true);
      const data = {
        fullName: fullName.trim().split(/\s+/).join(" "),
        email,
        mobile: `2${mobile.length === 10 ? "0" : ""}${mobile}`,
      };
      signUpApi(
        data,
        (success) => {
          if (success?.success) {
            setLoading(false);
            setIsModalOpen(true);
          } else {
            setLoading(false);
            toastError(success.message);
          }
        },
        (fail) => {
          if (fail?.data) {
            setLoading(false);
            toastError(fail.data?.message);
          } else {
            setLoading(false);
            toastError();
          }
        }
      );
    }
  };
  return !isAuthenticated ? (
    <section className="mct-section mct-section-signin d-flex justify-content-center align-items-center">
      <div className="w-100 fixed-top d-flex align-items-center justify-content-between py-3 px-4">
        <div className="logo">
          <img className="icon" src={logoIcon} alt="logo" />
        </div>
        <LanguageBtn inNavbar={false} />
      </div>
      <div className="contact-us__content">
        <Row justify={"center"} className="wd-100">
          <form onSubmit={onFinish} className="contact-us__form wd-100">
            <h1 className="text-center fsize-8 green-text w-100 mb-4">
              {t("auth.signup")}
            </h1>
            <div dir={dir} className="contact-us__form-field">
              <label className="ps-3 fsize-9">{t("auth.name")}</label>
              <input
                type={"text"}
                name="name"
                value={fullName}
                onChange={handleOnChangeName}
                className={`contact-us__form-input outline-0 fsize-8 ${nameError ? "border border-1 border-danger" : "border-0"
                  } `}
              />
              {nameError && (
                <span className="contact-us__field-error fsize-10 mt-1">
                  {t(`auth.${nameError}`)}
                </span>
              )}
            </div>
            <div dir={dir} className="contact-us__form-field my-3">
              <label className="ps-3 fsize-9">{t("auth.phone")}</label>
              <div
                className={`${dir === "rtl" ? "ps-0" : "pe-0"
                  } py-0 d-flex align-items-center gap-2 contact-us__form-input ${phoneError ? "border border-1 border-danger" : "border-0"
                  }`}
              >
                <div className="d-flex align-items-center gap-1">
                  <img src={flag} alt="" className="" />
                  <span className="fsize-9">+20</span>
                </div>
                <div className="flex-fill">
                  <input
                    type={"text"}
                    name="phone"
                    value={mobile}
                    onChange={handleOnChangePhone}
                    className={`contact-us__form-input ps-2 outline-0 fsize-8 border-0`}
                  />
                </div>
              </div>
              {phoneError && (
                <span className="contact-us__field-error fsize-10 mt-1">
                  {t(`auth.${phoneError}`)}
                </span>
              )}
            </div>
            <div dir={dir} className="contact-us__form-field mb-3">
              <label className="ps-3 fsize-9">Email</label>
              <input
                type={"text"}
                name="email"
                value={email}
                onChange={handleEmailChange}
                className={`contact-us__form-input fsize-8 outline-0 ${emailError ? "border border-1 border-danger" : "border-0"
                  } `}
              />
              {emailError && (
                <span className="contact-us__field-error fsize-10">
                  {t(`auth.${emailError}`)}
                </span>
              )}
            </div>
            <div
              dir={dir}
              className={`w-100 d-flex align-items-center gap-2 my-2`}
            >
              <input
                type="checkbox"
                name="terms-and-conditions"
                id="terms-checkbox"
                className={`${termsError ? "error" : ""} auth-checkbox`}
                checked={isTermsAccepted}
                onChange={handleTermsConditions}
              />
              <label className="fsize-10">
                {t("auth.accept")}{" "}
                <span
                  onClick={() => setIsTermsModalOpen(true)}
                  className="terms-text text-underline"
                >
                  {t("auth.termsAndConditions")}
                </span>
              </label>
            </div>
            <div className="sign-in-btns mt-2 mt-md-4 d-flex gap-3 flex-column justify-content-between align-items-center w-100">
              <Button
                loading={loading}
                className="sign-in-btn border-0 fsize-9 h-auto"
                htmlType="submit"
              >
                {t("auth.signup")}
              </Button>
              <div className="sign-up-btn fsize-9">
                <span className="white-text">{t("auth.withAccount")} </span>
                <Link
                  to={ROUTES.SIGNIN}
                  className="fw-semibold green-text h-auto"
                >
                  {t("auth.login")}
                </Link>
              </div>
            </div>
          </form>
        </Row>
        {isModalOpen && (
          <GlobalModal
            name="otp-modal"
            isModalOpen={isModalOpen}
            onCancel={onCancel}
            onAction={onAction}
            data={{
              mobile: `2${mobile.length === 10 ? "0" : ""}${mobile}`,
              fireBaseToken: "fireBaseToken",
              deviceId: "deviceId",
            }}
          />
        )}
        {isTermsModalOpen && (
          <TermAndConditionsModal
            isOpen={isTermsModalOpen}
            data={terms}
            onCancel={() => setIsTermsModalOpen(false)}
          />
        )}
      </div>
    </section>
  ) : (
    <div>
      <Spin
        size="large"
        className="spin-overlay w-100 h-100 position-absolute top-0 start-0 d-flex justify-content-center align-items-center"
      ></Spin>
    </div>
  );
}

export default SignUp;
