import { useTranslation } from "react-i18next";

// assets
import emptyImg from "../../assets/images/buy-and-supply-empty-state.png"

const ItemsEmptyState = ({ mode }) => {
    const { t } = useTranslation();

    return (
        <div className="buy-and-supply__empty-state py-5 my-5 d-flex flex-column align-items-center">
            <img src={emptyImg} alt="" className="d-block img-fluid mb-4" />
            <p className="fsize-4 fw-bold">{t("buyAndSupply.pageIsEmpty")}</p>
            <p className="fsize-5 fw-500 mb-0">{t(`buyAndSupply.${mode === "buy" ? "checkItemsLater" : "checkTypesLater"}`)}</p>
        </div>
    );
}

export default ItemsEmptyState;