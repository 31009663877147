import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Modal } from "antd";

// assets
import winnerGif from "../../../../assets/images/auction-winner.gif"

const WinnerModal = ({ open, onShowPaymentMethod }) => {
    const { dir } = useSelector((state) => state.language);
    const { t } = useTranslation();

    return (
        <Modal
            centered
            width={window.innerWidth >= 992 ? "60%" : window.innerWidth >= 768 ? "80%" : "90%"}
            open={open}
            footer={false}
            closable={false}
            destroyOnClose
            maskClosable={false}
            className="bidders-denied-access-modal"
        >
            <img src={winnerGif} alt="" className="d-block mx-auto mb-3 mb-xxl-4" />
            <p className="text-center fw-bold green-text fsize-4 mb-xxl-4">{t("bidders.chat.congratulations")}</p>
            <p dir={dir} className="text-center fw-bold main-dark-text fsize-8 mb-0" >
                {t("bidders.chat.winnerMsg")}
            </p>
            <button
                type="button"
                onClick={onShowPaymentMethod}
                className="view-auction-details-modal__join-btn d-block border-0 outline-none shadow-none fw-bold fsize-5 text-center mx-auto mt-5"
            >
                {t("auctionRequests.selectPaymentMethod")}
            </button>
        </Modal>
    );
}

export default WinnerModal;