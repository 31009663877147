import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// assets
import defaultGroupImg from "../../../../assets/images/default-group.png"

const ChatMessage = ({ data }) => {
    const [messageOfCurrentUser, setMessageOfCurrentUser] = useState(false);
    const [timeOfMsg, setTimeOfMsg] = useState("");
    const { authorization } = useSelector((state) => state.auth);
    const { dir } = useSelector((state) => state.language);
    const { t } = useTranslation();

    useEffect(() => {
        if (data && authorization) {
            const fullDate = new Date(data.timestamp);
            const timeArr = fullDate.toTimeString().split()[0].split(":");
            const timeString = `${timeArr[0]}:${timeArr[1]}`
            setTimeOfMsg(timeString)
            if (data.userId === authorization.userId) {
                setMessageOfCurrentUser(true)
            } else {
                setMessageOfCurrentUser(false)
            }
        }
    }, [data, authorization])

    return (
        <div className={`auction-chat__chat-holder__body__message d-flex ${messageOfCurrentUser ? "justify-content-end" : "gap-2"}`}>
            {messageOfCurrentUser ? (
                ""
            ) : (
                <img
                    src={defaultGroupImg}
                    className="d-block img-fluid object-fit-cover"
                    alt={`${data?.userName}`}
                />
            )}
            <div className={`auction-chat__chat-holder__body__message__holder ${messageOfCurrentUser ? "auction-chat__chat-holder__body__message__holder--sent-by-current" : ""}`}>
                <p
                    dir={messageOfCurrentUser ? dir === "rtl" ? "ltr" : "rtl" : dir}
                    className="green-text fw-bold fsize-11"
                >
                    {`${messageOfCurrentUser ? t("bidders.chat.you") : data?.userName}`}
                </p>
                <p
                    dir={messageOfCurrentUser ? dir === "rtl" ? "ltr" : "rtl" : dir}
                    className="text--natural-black fw-bold fsize-11"
                >
                    {data?.bid}
                </p>
                <p
                    dir={messageOfCurrentUser ? dir : dir === "ltr" ? "rtl" : "ltr"}
                    className="fw-bold fsize-11 gray--8 mb-0"
                >
                    {timeOfMsg}
                </p>
            </div>
        </div>
    );
}

export default ChatMessage;