import { useTranslation } from "react-i18next";

// assets
import clockIcon from "../../../assets/icons/auction-date-icon.svg"

const AuctionInfo = ({ data }) => {
    const { t } = useTranslation();

    return (
        <div className="auction-chat__auction-info">
            <h1 className="green-text fw-bold fsize-4 mb-4 mb-xxl-5 d-flex align-items-center justify-content-center justify-content-lg-start">{data?.type}</h1>
            <ul className="bidders__auction__info-list ps-0 w-100 mb-4 d-flex align-items-center justify-content-center justify-content-lg-start">
                <li className="fsize-11 fw-bold mb-3 text-white">{t("auctionRequests.maxQuantityPerUser")}: {data?.maximumAuctionAmountForUserInKilo} {t("auctionRequests.kg")}</li>
            </ul>
            <div className="d-flex align-items-center justify-content-center justify-content-lg-start gap-3 mb-4">
                <p className="text-white fw-bold fsize-6 mb-0">{t("bidders.minBidding")}</p>
                <span className="d-block bidders__auction__minBiddingValue fw-bold fsize-6">
                    {data?.minimumBidding} {t("notificationModal.egp")}
                </span>
            </div>
            <div className="d-flex align-items-center justify-content-center justify-content-lg-start gap-3 mb-4">
                <p className="text-white fw-bold fsize-6 mb-0">{t("bidders.incrementValue")}</p>
                <span className="d-block bidders__auction__minBiddingValue fw-bold fsize-6">
                    {data?.incrementValue} {t("notificationModal.egp")}
                </span>
            </div>
            <div className="d-flex align-items-center justify-content-center justify-content-lg-start gap-2 mb-4">
                <img src={clockIcon} alt="" className="auction-chat__auction-info__clock-icon img-fluid d-block" />
                <span className="text-white fw-bold fsize-6">{`${t("bidders.started")}${data?.startDate.split("-")[1]}`}</span>
            </div>
        </div>
    );
}

export default AuctionInfo;